import React from 'react';

const IconLoader = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="200.000000pt"
    height="2000.000000pt"
    viewBox="0 0 2000.000000 2000.000000"
    preserveAspectRatio="xMidYMid meet">
    <metadata>Created by potrace 1.16, written by Peter Selinger 2001-2019</metadata>
    <g
      transform="translate(0.000000,2000.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none">
      <path
        d="M8863 17385 c-45 -19 -111 -91 -123 -134 -8 -25 -10 -1099 -8 -3477
  l3 -3441 28 -41 c16 -22 47 -53 69 -69 37 -25 50 -28 123 -28 73 0 86 3 123
  28 52 36 92 95 103 149 4 24 8 1073 9 2331 l0 2289 628 -5 c601 -4 630 -5 704
  -25 250 -69 428 -244 504 -497 17 -57 19 -166 24 -2086 l5 -2026 30 -48 c45
  -74 101 -108 180 -113 80 -5 116 8 173 62 79 74 72 -120 72 2140 0 2173 2
  2093 -48 2251 -23 74 -82 191 -132 265 -51 74 -222 238 -292 280 l-57 34 77
  57 c203 148 353 366 419 604 25 94 27 113 27 305 1 182 -2 215 -23 300 -52
  208 -158 396 -314 553 -120 120 -193 175 -313 237 -108 55 -193 84 -317 106
  -118 20 -1626 20 -1674 -1z m1654 -467 c246 -75 442 -278 509 -528 25 -92 25
  -290 0 -388 -65 -255 -267 -462 -518 -532 -69 -19 -101 -20 -695 -20 l-623 0
  0 745 0 746 638 -4 c548 -3 644 -5 689 -19z"
      />
      <path
        d="M10010 14134 c-45 -19 -107 -79 -128 -124 -16 -32 -17 -278 -20
  -3422 l-2 -3388 -458 0 c-424 0 -460 -2 -497 -19 -81 -38 -145 -133 -145 -216
  0 -83 64 -178 145 -216 38 -18 90 -19 1195 -19 1307 0 1195 -7 1277 83 86 94
  83 226 -9 317 -70 71 -62 70 -570 70 l-458 0 0 3374 c0 3749 6 3422 -68 3500
  -20 21 -55 47 -77 57 -49 23 -136 24 -185 3z"
      />
      <path
        d="M4650 13395 l0 -155 1150 0 1150 0 0 155 0 155 -1150 0 -1150 0 0
  -155z"
      />
      <path
        d="M13390 13395 l0 -155 1145 0 1145 0 0 155 0 155 -1145 0 -1145 0 0
  -155z"
      />
      <path
        d="M4650 12685 l0 -155 1150 0 1150 0 0 155 0 155 -1150 0 -1150 0 0
  -155z"
      />
      <path
        d="M13390 12685 l0 -155 1145 0 1145 0 0 155 0 155 -1145 0 -1145 0 0
  -155z"
      />
      <path
        d="M6420 5155 c-109 -10 -164 -20 -220 -40 -41 -15 -106 -36 -145 -47
  -69 -19 -215 -101 -281 -159 -18 -14 -56 -43 -85 -62 -108 -73 -288 -315 -328
  -444 -12 -37 -26 -75 -30 -84 -5 -8 -16 -49 -25 -90 -9 -40 -21 -93 -26 -117
  -13 -54 -13 -483 -1 -531 5 -20 15 -62 21 -95 7 -32 25 -86 41 -120 16 -33 29
  -69 29 -79 0 -49 172 -287 296 -411 86 -85 102 -96 245 -168 85 -43 172 -82
  194 -87 22 -5 65 -17 95 -26 183 -54 210 -58 410 -51 297 11 338 22 729 203
  19 8 98 79 176 157 106 105 152 159 181 211 21 39 49 88 62 110 46 80 79 161
  86 209 3 27 10 54 14 60 23 30 36 162 35 361 0 217 -4 254 -48 400 -13 44 -27
  91 -30 105 -3 14 -31 76 -62 138 -43 88 -73 133 -138 205 -99 112 -182 188
  -250 228 -27 17 -70 42 -95 57 -48 29 -61 34 -220 82 -58 18 -145 45 -195 60
  -112 35 -234 42 -435 25z m385 -307 c201 -41 271 -72 416 -185 84 -66 127
  -107 168 -165 86 -118 101 -145 128 -232 13 -45 29 -92 35 -106 27 -60 48
  -401 29 -474 -5 -23 -17 -75 -26 -116 -27 -125 -37 -151 -98 -257 -60 -104
  -91 -142 -209 -250 -72 -67 -112 -91 -217 -130 -36 -14 -73 -31 -81 -38 -8 -7
  -55 -23 -105 -35 -76 -19 -114 -22 -245 -21 -85 1 -171 4 -190 7 -155 23 -178
  30 -355 114 -22 11 -62 38 -90 61 -248 205 -355 394 -376 664 -23 295 -23 309
  -1 392 11 43 24 98 27 122 18 113 149 310 296 444 61 56 91 74 198 121 164 71
  250 98 336 107 101 10 245 1 360 -23z"
      />
      <path
        d="M6475 4799 c-49 -4 -106 -12 -125 -18 -19 -5 -57 -15 -85 -21 -126
  -28 -197 -71 -365 -222 -96 -87 -120 -117 -148 -188 -5 -14 -20 -47 -33 -75
  -12 -27 -29 -79 -37 -115 -7 -36 -18 -85 -24 -110 -16 -73 -22 -336 -8 -404
  24 -119 74 -261 105 -296 12 -14 39 -53 60 -88 26 -43 68 -88 129 -141 141
  -121 150 -125 371 -187 57 -16 219 -37 285 -36 86 0 282 32 328 52 12 5 32 14
  45 19 85 35 137 62 171 90 21 17 42 31 46 31 14 0 98 88 130 137 18 26 41 57
  51 68 22 25 81 145 104 210 43 128 56 322 35 523 -13 120 -18 136 -97 301 -71
  147 -111 195 -248 296 -69 51 -98 67 -222 119 -117 49 -284 69 -468 55z m335
  -319 c24 -12 65 -31 90 -42 123 -53 215 -164 275 -333 33 -93 36 -108 36 -220
  0 -115 -6 -164 -30 -286 -16 -80 -111 -221 -191 -282 -33 -26 -63 -47 -68 -47
  -4 0 -28 -11 -54 -25 -66 -35 -145 -48 -288 -48 -218 -1 -321 40 -460 183 -85
  87 -85 88 -125 206 l-40 118 0 166 c0 201 11 244 96 370 60 91 102 131 203
  195 100 64 154 74 361 71 133 -3 155 -5 195 -26z"
      />
      <path
        d="M13445 5133 c-54 -19 -119 -85 -145 -147 -12 -28 -44 -98 -72 -156
  -27 -58 -54 -114 -58 -125 -5 -11 -20 -46 -34 -78 -14 -32 -26 -61 -26 -66 0
  -4 -13 -27 -30 -49 -16 -23 -30 -50 -30 -60 0 -10 -13 -44 -30 -75 -16 -32
  -30 -62 -30 -67 0 -9 -40 -95 -167 -360 -31 -63 -75 -155 -98 -205 -24 -49
  -57 -119 -75 -155 -17 -36 -38 -85 -46 -110 -15 -45 -55 -132 -102 -225 -51
  -98 -66 -134 -78 -175 -3 -14 -17 -39 -29 -55 -13 -17 -30 -51 -38 -75 -8 -25
  -34 -83 -56 -129 -62 -128 -56 -211 16 -230 56 -14 415 -11 481 4 66 16 109
  55 159 145 43 79 76 116 126 141 36 19 58 19 450 17 l413 -3 52 -39 c51 -37
  87 -92 106 -158 13 -47 145 -118 223 -119 146 -3 328 3 381 12 55 10 63 14 68
  38 3 14 8 33 10 41 8 32 -40 158 -122 320 -29 58 -62 130 -74 160 -11 30 -48
  109 -80 175 -33 66 -73 154 -90 195 -18 41 -38 89 -46 107 -8 17 -14 35 -14
  40 0 5 -15 32 -34 61 -18 29 -41 74 -50 100 -9 26 -30 74 -46 108 -17 34 -30
  64 -30 67 0 3 -16 34 -36 69 -20 34 -46 90 -59 123 -12 33 -50 116 -83 185
  -129 270 -169 357 -176 385 -4 16 -15 40 -25 54 -10 14 -24 44 -31 66 -21 63
  -79 162 -119 203 -59 59 -142 75 -226 45z m213 -203 c25 -23 28 -39 10 -44
  -49 -15 -50 -16 -38 -31 7 -8 25 -15 40 -15 22 0 36 -10 59 -42 17 -23 31 -51
  31 -62 0 -12 9 -32 20 -46 23 -30 25 -41 6 -34 -8 3 -17 0 -21 -6 -9 -15 23
  -50 47 -50 10 0 18 -5 18 -11 0 -12 -24 -6 -83 19 -37 16 -37 17 -31 57 6 44
  -3 59 -41 67 -20 3 -22 18 -13 73 2 16 -3 20 -27 20 -19 0 -40 10 -57 26 -28
  27 -40 51 -14 30 7 -6 21 -9 30 -5 21 8 21 50 -1 63 -15 9 -13 10 13 11 17 0
  40 -9 52 -20z m-100 -187 c11 -16 42 -75 70 -133 237 -497 251 -527 269 -579
  8 -25 28 -74 44 -109 16 -35 29 -65 29 -66 0 -2 22 -50 49 -107 63 -134 111
  -240 111 -243 0 -2 25 -56 55 -120 30 -64 55 -118 55 -121 0 -3 18 -38 41 -78
  81 -143 150 -337 120 -337 -18 0 -65 66 -98 138 -41 89 -90 145 -152 174 -43
  21 -56 22 -226 18 -99 -3 -234 -4 -300 -4 -207 0 -517 2 -595 4 -144 3 -210
  -35 -274 -160 -19 -36 -46 -89 -61 -117 -19 -35 -34 -53 -46 -53 -25 0 -24 29
  2 88 12 26 32 70 45 99 13 28 24 55 24 58 0 4 8 19 19 33 10 15 40 79 66 142
  26 63 53 129 60 145 52 119 65 145 88 183 15 23 27 47 27 53 0 12 32 83 115
  254 29 61 64 135 78 165 39 88 76 167 133 290 30 64 54 121 54 128 0 6 14 34
  30 60 17 27 30 52 30 56 0 4 16 43 36 86 30 66 41 80 60 80 13 0 30 -11 42
  -27z m-990 -1702 l-21 -14 21 -17 c19 -16 19 -20 7 -36 -8 -11 -15 -31 -15
  -45 0 -14 -11 -36 -25 -49 -14 -13 -25 -30 -25 -37 0 -8 -8 -13 -17 -12 -40 4
  -53 -2 -53 -21 0 -13 -7 -20 -19 -20 -24 0 -36 29 -15 37 12 4 14 14 10 38 -8
  41 21 59 43 26 16 -25 29 -26 53 -4 24 21 23 37 -2 30 -13 -3 -20 0 -20 9 0 8
  9 14 19 14 33 0 32 28 -2 54 l-32 26 23 15 c12 9 22 22 22 29 0 10 7 11 34 2
  33 -10 34 -11 14 -25z"
      />
      <path
        d="M13482 4568 c-18 -20 -52 -88 -116 -231 -8 -18 -33 -72 -56 -122 -23
  -49 -51 -112 -61 -139 -11 -27 -28 -61 -38 -75 -10 -14 -29 -48 -41 -76 -12
  -27 -39 -86 -60 -130 -20 -44 -45 -98 -55 -120 -10 -22 -32 -71 -51 -110 -106
  -227 -112 -250 -69 -289 21 -20 41 -24 142 -32 65 -5 315 -9 555 -9 428 0 437
  0 465 21 41 30 42 77 4 159 -17 37 -31 68 -31 69 0 2 -22 50 -49 107 -27 57
  -60 130 -75 162 -58 127 -81 177 -127 274 -27 57 -49 105 -49 107 0 2 -22 50
  -49 107 -86 183 -113 243 -127 283 -24 66 -72 85 -112 44z m71 -590 c18 -14
  177 -382 177 -411 0 -32 -19 -55 -55 -67 -38 -13 -291 -4 -332 11 -42 16 -29
  75 56 261 15 35 39 88 52 118 42 100 62 117 102 88z"
      />
      <path
        d="M15220 5129 c-30 -5 -96 -7 -147 -3 -103 7 -154 -5 -178 -39 -16 -23
  -20 -92 -6 -101 5 -3 11 -16 15 -29 6 -25 142 -253 199 -334 19 -26 58 -91 87
  -143 29 -52 74 -124 100 -160 26 -36 64 -95 84 -131 20 -37 63 -109 95 -160
  256 -403 233 -318 237 -889 3 -533 1 -520 87 -544 38 -11 106 -14 252 -14 302
  1 297 -5 314 319 8 153 8 221 -1 267 -9 48 -9 77 1 125 7 34 11 109 10 167 -3
  143 16 223 80 325 17 28 49 82 71 120 23 39 61 102 85 140 24 39 60 98 80 133
  63 110 187 306 210 333 37 45 268 431 282 473 19 54 9 114 -21 126 -12 4 -139
  10 -282 13 -215 4 -266 3 -304 -10 -54 -18 -117 -70 -147 -121 -63 -106 -77
  -132 -100 -187 -14 -33 -39 -80 -57 -103 -17 -24 -69 -119 -116 -210 -73 -144
  -88 -167 -110 -170 -28 -2 -38 11 -153 218 -38 69 -73 130 -77 135 -8 10 -23
  38 -125 230 -84 158 -139 204 -260 217 -38 4 -88 9 -110 12 -22 2 -65 0 -95
  -5z m208 -355 c34 -46 68 -97 74 -112 13 -29 94 -160 157 -252 21 -30 50 -78
  64 -105 14 -28 56 -97 95 -155 38 -58 84 -131 102 -163 30 -54 92 -117 115
  -117 30 0 195 217 195 256 0 7 10 25 23 41 13 15 36 51 52 78 15 28 43 68 61
  91 19 22 49 74 67 115 19 41 41 80 50 87 9 7 41 55 72 106 94 156 185 255 185
  203 0 -22 -59 -131 -113 -207 -24 -36 -77 -121 -117 -190 -39 -69 -82 -138
  -94 -155 -13 -16 -38 -57 -56 -90 -18 -33 -47 -80 -65 -105 -17 -25 -42 -65
  -54 -89 -23 -45 -76 -128 -110 -171 -11 -14 -30 -61 -42 -105 -22 -76 -23
  -100 -24 -455 -1 -369 -6 -430 -35 -430 -29 0 -35 112 -28 555 1 33 -4 119
  -10 192 -9 105 -17 148 -42 212 -17 45 -37 87 -44 94 -29 29 -95 126 -120 177
  -16 30 -36 64 -46 75 -10 11 -35 47 -55 80 -20 33 -68 110 -105 170 -86 137
  -217 356 -247 413 l-22 42 27 0 c22 -1 38 -16 90 -86z m-272 50 c31 -15 34
  -20 29 -45 -6 -27 -4 -29 33 -35 37 -6 40 -9 45 -46 3 -22 3 -56 0 -76 -6 -32
  -4 -35 13 -28 10 5 31 10 46 12 24 5 28 1 34 -31 5 -29 13 -39 35 -47 16 -6
  29 -15 28 -22 -1 -6 1 -23 5 -38 6 -21 15 -28 32 -28 20 0 24 -5 24 -28 0 -15
  5 -33 11 -39 13 -13 3 -36 -12 -28 -5 4 -9 -1 -9 -11 0 -11 6 -15 19 -11 10 2
  26 0 36 -6 18 -9 18 -10 0 -23 -23 -17 -13 -42 20 -46 14 -2 24 -7 23 -13 -2
  -5 -7 -9 -11 -7 -4 1 -32 -2 -62 -6 -49 -8 -55 -7 -55 8 0 10 -7 23 -15 30 -8
  7 -15 23 -15 37 0 18 -8 27 -30 34 -37 13 -38 33 -5 55 24 16 34 44 16 44 -5
  0 -11 10 -13 22 -3 25 -28 37 -28 15 0 -17 -26 -47 -41 -47 -5 0 -15 12 -21
  28 -7 15 -18 35 -26 44 -10 12 -13 31 -9 58 4 30 1 43 -9 47 -8 3 -14 10 -14
  17 0 6 -13 19 -29 29 -20 12 -32 29 -40 62 -9 37 -17 47 -46 59 -36 16 -46 41
  -23 64 16 16 23 15 64 -4z m650 -1014 c8 -7 14 -22 14 -34 0 -12 5 -27 12 -34
  6 -6 11 -32 11 -57 0 -31 5 -45 14 -45 7 0 13 7 13 16 0 9 6 14 13 12 7 -3 12
  -21 12 -44 0 -37 -2 -39 -33 -42 -29 -3 -32 -6 -26 -25 8 -24 32 -46 52 -47 7
  0 12 -14 12 -38 0 -21 7 -47 15 -58 11 -15 13 -36 8 -94 -3 -41 -10 -84 -16
  -95 -7 -13 -6 -20 2 -23 15 -5 14 -29 -1 -35 -10 -3 -8 -9 5 -24 23 -26 24
  -164 2 -207 -19 -36 -19 -67 0 -92 8 -10 15 -29 15 -41 0 -16 -5 -20 -20 -16
  -14 4 -20 0 -20 -11 0 -10 9 -17 23 -17 22 -2 22 -2 1 -6 -30 -6 -39 -43 -13
  -50 24 -7 15 -23 -13 -23 -11 0 -22 8 -25 20 -5 20 -36 35 -52 26 -15 -10 -21
  22 -21 110 0 49 4 84 10 84 6 0 10 5 10 10 0 7 -6 7 -19 0 -12 -6 -22 -6 -25
  -1 -12 18 7 72 32 92 l26 20 -22 15 c-17 11 -20 21 -15 44 3 19 1 30 -6 30
  -15 0 -14 27 1 32 8 3 5 14 -9 34 -15 21 -19 35 -12 45 5 7 9 26 9 42 0 17 5
  27 14 27 26 0 29 21 7 44 -18 19 -21 35 -21 115 0 76 3 97 19 117 24 31 21
  112 -5 131 -16 11 -16 13 -2 13 31 0 32 23 3 46 -16 12 -33 38 -39 56 -11 31
  -9 34 14 44 24 10 25 9 28 -22 2 -18 10 -38 18 -44z m412 -122 c-3 -26 1 -33
  21 -37 19 -5 24 -14 28 -46 5 -46 5 -42 -3 -116 -5 -45 -3 -59 12 -73 15 -15
  16 -23 6 -48 -6 -16 -8 -36 -5 -44 3 -9 0 -26 -7 -39 -11 -20 -10 -31 4 -66
  25 -62 14 -114 -26 -128 -25 -9 -28 -13 -18 -26 6 -8 20 -17 31 -20 17 -6 19
  -17 19 -105 0 -91 -2 -100 -21 -111 -12 -6 -17 -14 -12 -18 15 -9 25 -59 13
  -66 -5 -3 -44 4 -86 15 -45 13 -87 19 -103 15 l-26 -6 31 33 c44 47 54 69 54
  127 0 28 6 60 12 71 10 16 10 22 -2 35 -12 12 -14 29 -8 87 5 40 9 89 10 108
  3 30 7 35 31 38 30 4 38 31 10 39 -21 5 -54 69 -46 89 3 8 14 19 24 25 25 13
  24 44 -1 79 -27 37 -18 93 24 167 16 29 32 53 33 53 2 0 2 -14 1 -32z"
      />
      <path
        d="M2979 5120 c-62 -8 -97 -33 -123 -89 l-22 -48 3 -1128 c4 -1308 -3
  -1210 97 -1259 49 -23 65 -27 90 -19 17 5 49 6 71 1 83 -16 304 8 352 38 34
  21 52 102 51 234 -1 395 2 417 59 453 32 21 42 22 112 14 42 -4 92 -12 111
  -17 47 -12 102 -68 136 -138 15 -31 44 -82 65 -112 20 -30 51 -82 69 -115 18
  -33 41 -71 51 -86 9 -14 32 -55 50 -92 41 -86 105 -143 186 -164 41 -10 128
  -15 298 -17 228 -3 242 -2 280 18 65 34 73 105 21 177 -14 19 -37 56 -52 84
  -15 27 -58 97 -95 154 -38 58 -69 108 -69 112 0 10 -95 163 -136 220 -61 84
  -41 181 56 273 28 28 56 59 61 70 5 12 27 53 49 91 61 105 76 142 96 230 30
  136 14 447 -26 500 -4 6 -11 26 -15 45 -17 87 -72 173 -184 292 -153 160 -232
  202 -501 264 -52 12 -164 16 -580 18 -283 1 -536 0 -561 -4z m1117 -291 c218
  -53 359 -179 435 -392 19 -54 22 -81 22 -217 -1 -142 -3 -161 -27 -222 -47
  -122 -141 -244 -217 -284 -19 -9 -63 -37 -97 -63 -63 -46 -64 -46 -59 -88 3
  -29 31 -86 92 -190 49 -81 105 -179 125 -218 21 -38 67 -119 104 -180 36 -60
  66 -115 66 -122 0 -22 -40 -9 -69 22 -16 16 -41 55 -57 85 -15 30 -37 69 -50
  86 -12 16 -71 111 -131 210 -183 299 -179 295 -259 319 -67 20 -639 22 -697 1
  -70 -24 -73 -36 -86 -361 -6 -160 -14 -305 -17 -322 -9 -47 -32 -41 -39 10 -3
  23 -5 449 -3 947 4 965 3 949 52 981 17 11 96 15 371 19 407 6 433 5 541 -21z
  m392 -1099 c27 -37 36 -37 79 -4 43 32 61 27 33 -9 -15 -18 -28 -26 -40 -22
  -14 5 -21 -6 -36 -55 -13 -41 -25 -62 -39 -66 -11 -4 -33 -20 -49 -35 -22 -22
  -27 -32 -19 -40 19 -19 3 -39 -38 -45 -73 -12 -56 -39 24 -39 46 0 50 -2 70
  -39 16 -28 19 -40 10 -43 -19 -7 -15 -36 7 -56 11 -9 20 -25 20 -36 0 -10 9
  -21 20 -24 24 -6 27 -48 5 -67 -19 -16 -10 -30 20 -30 29 0 56 -36 49 -64 -3
  -15 6 -32 36 -61 28 -28 40 -47 40 -68 0 -39 -17 -67 -40 -67 -21 0 -27 -19
  -11 -35 14 -15 1 -64 -19 -75 -13 -7 -23 -3 -41 16 l-24 25 21 17 c15 11 23
  32 26 63 3 26 12 60 20 77 20 39 9 54 -38 56 -39 1 -39 2 -70 76 -20 47 -50
  95 -83 131 -28 32 -51 60 -51 63 0 2 10 6 22 8 29 4 34 31 11 57 -18 22 -53
  29 -53 11 0 -6 -13 4 -29 22 -22 25 -28 40 -24 61 4 17 1 27 -7 27 -7 0 -10 8
  -7 20 3 11 -2 27 -10 35 -10 11 -12 19 -5 28 6 7 12 26 14 42 3 29 5 30 47 27
  44 -3 44 -3 38 27 -5 26 0 36 40 75 25 25 49 46 53 46 3 0 16 -13 28 -30z
  m-687 -270 c12 0 19 7 19 20 0 27 25 36 45 16 9 -9 25 -16 35 -16 13 0 17 -5
  13 -18 -7 -27 21 -46 46 -33 11 6 28 11 38 11 23 0 83 -53 83 -74 0 -8 14 -26
  30 -39 17 -15 30 -36 30 -48 0 -12 7 -31 17 -41 15 -17 15 -18 -5 -18 -30 0
  -28 -28 3 -42 14 -6 25 -20 25 -30 0 -12 12 -24 34 -33 44 -19 52 -32 32 -54
  -21 -24 -20 -43 2 -39 26 4 52 -20 52 -47 0 -12 9 -30 20 -40 26 -24 21 -39
  -16 -45 -23 -4 -29 -10 -29 -30 0 -18 6 -26 23 -28 14 -2 22 -10 22 -22 0 -24
  38 -46 52 -32 7 7 2 19 -16 35 -18 16 -23 28 -16 32 18 11 50 -25 50 -57 0
  -33 -24 -47 -49 -30 -41 29 -82 44 -106 38 -14 -4 -25 -2 -25 3 0 5 -20 33
  -45 63 -25 29 -41 54 -35 56 23 8 7 44 -45 100 -42 46 -57 71 -67 111 -7 33
  -19 56 -34 66 -13 9 -26 30 -30 50 -3 19 -11 35 -18 35 -6 0 -30 18 -54 40
  -51 48 -166 104 -196 96 -13 -3 -29 2 -43 16 -19 17 -25 19 -38 8 -8 -7 -15
  -21 -15 -31 0 -11 -4 -19 -10 -19 -5 0 -7 13 -4 29 4 18 0 38 -11 55 -12 18
  -13 26 -5 26 16 0 70 -32 70 -42 0 -4 21 -8 48 -8 40 0 51 4 72 30 l25 31 3
  -26 c2 -17 9 -25 23 -25z m-361 7 c12 -15 30 -27 41 -27 11 0 22 -8 25 -17 3
  -10 9 -25 14 -34 13 -24 -27 -61 -40 -39 -6 8 -10 18 -10 21 0 3 -15 13 -34
  22 -30 14 -36 14 -46 1 -16 -18 -31 -18 -24 1 8 19 -24 20 -51 1 -18 -12 -20
  -11 -21 10 -1 14 4 24 12 24 7 0 20 15 28 33 13 27 21 32 50 32 25 0 40 -7 56
  -28z"
      />
      <path
        d="M3259 4777 c-27 -14 -38 -28 -46 -60 -11 -39 -29 -362 -25 -432 1
  -16 4 -131 7 -255 8 -362 13 -370 240 -371 271 -1 301 -1 459 5 209 7 293 31
  395 114 66 54 154 178 175 248 9 32 22 69 27 82 11 28 12 244 1 258 -4 5 -16
  36 -27 69 -10 33 -25 67 -32 76 -7 8 -13 20 -13 25 0 15 -121 129 -166 157
  -57 35 -207 85 -279 92 -33 4 -200 7 -371 8 -283 2 -314 0 -345 -16z m686
  -255 c137 -18 134 -16 203 -113 38 -53 55 -139 45 -229 -13 -120 -72 -194
  -187 -231 -63 -21 -91 -24 -241 -25 -196 -1 -235 9 -254 68 -6 18 -12 125 -13
  238 -3 273 4 287 152 301 89 8 185 5 295 -9z m-599 -523 c3 -6 19 -9 34 -6 23
  5 31 0 58 -38 20 -28 29 -51 25 -64 -4 -15 -1 -21 11 -21 9 0 16 5 16 10 0 6
  6 10 14 10 17 0 31 -33 17 -38 -27 -9 -54 -40 -48 -55 10 -25 93 -24 106 2 10
  19 11 19 47 0 39 -19 54 -17 54 7 0 19 26 18 34 -2 4 -10 -3 -26 -19 -42 -25
  -24 -26 -25 -40 -6 -12 15 -16 16 -23 6 -8 -11 -13 -11 -29 -1 -17 10 -23 9
  -42 -10 -11 -11 -32 -21 -46 -21 -29 0 -93 41 -88 57 2 6 -5 13 -16 16 -14 3
  -18 11 -14 26 4 14 1 21 -9 21 -22 0 -29 -12 -16 -27 6 -7 9 -16 6 -19 -7 -6
  -61 17 -70 31 -17 22 -5 27 57 23 61 -4 66 -3 63 16 -2 12 -13 23 -27 26 -21
  5 -22 7 -7 19 16 14 22 46 9 54 -5 3 -29 2 -54 -2 -45 -7 -45 -7 -35 16 12 25
  21 29 32 12z"
      />
      <path
        d="M8220 5114 c-16 -7 -40 -30 -54 -51 -25 -38 -26 -44 -28 -224 -3
  -214 6 -251 70 -286 33 -19 57 -21 246 -22 229 -2 251 -7 285 -62 18 -29 19
  -73 18 -905 l-1 -874 25 -37 c42 -65 147 -86 364 -74 190 10 223 25 253 109
  l23 61 -2 818 c0 450 2 832 6 848 26 116 37 121 294 117 202 -3 204 -3 241 22
  63 41 77 87 85 267 7 155 6 157 -19 208 -16 31 -41 61 -64 76 l-37 25 -838 -2
  c-636 -2 -844 -5 -867 -14z m1483 -259 c44 -6 57 -11 57 -24 0 -31 -56 -39
  -255 -34 -173 4 -197 2 -268 -18 -128 -36 -122 -15 -117 -424 3 -187 3 -416 0
  -510 -3 -93 -4 -217 -4 -275 4 -326 3 -641 -2 -681 -5 -38 -9 -46 -24 -42 -10
  3 -20 18 -24 35 -3 17 -6 424 -6 905 l0 874 -25 49 c-39 77 -81 94 -209 86
  -131 -8 -112 -8 -261 1 -133 9 -155 14 -155 37 0 23 97 28 369 22 141 -4 299
  -3 351 1 117 10 494 8 573 -2z m-408 -812 c-49 -117 -51 -135 -14 -126 18 5
  20 3 14 -16 -4 -12 -2 -21 4 -21 22 0 26 -2 34 -15 4 -9 -1 -32 -13 -57 -27
  -57 -25 -88 10 -128 16 -19 29 -45 30 -56 0 -18 -5 -20 -26 -16 -21 4 -25 2
  -18 -9 5 -8 11 -42 14 -76 4 -50 2 -64 -10 -68 -12 -5 -12 -7 0 -15 22 -13
  -33 -24 -58 -11 -15 8 -20 21 -20 56 -1 26 -8 60 -17 77 -8 17 -13 33 -11 35
  6 7 45 -12 45 -22 0 -5 4 -11 10 -13 24 -8 9 41 -20 67 -32 30 -34 63 -4 75
  17 6 21 36 5 36 -21 0 -30 49 -18 101 9 40 9 52 -1 59 -23 14 -4 111 28 137
  32 27 46 29 36 6z m25 -699 c0 -14 -7 -27 -15 -30 -20 -8 -19 -20 5 -44 22
  -22 29 -110 9 -110 -6 0 -9 6 -6 14 3 8 0 19 -7 26 -10 8 -15 6 -20 -6 -10
  -25 3 -56 31 -80 27 -23 24 -44 -6 -44 -10 0 -24 -7 -32 -16 -18 -22 -1 -46
  39 -56 35 -9 39 -22 20 -63 -10 -23 -17 -26 -57 -24 -54 4 -68 -17 -22 -34 17
  -6 31 -15 31 -19 0 -4 7 -8 15 -8 19 0 39 -70 31 -112 -5 -26 -18 -42 -52 -66
  -44 -32 -47 -33 -121 -26 -66 6 -76 10 -84 30 -10 29 -25 31 -32 4 -9 -36 -50
  -45 -109 -24 -52 19 -53 20 -50 57 3 31 1 37 -17 37 -16 0 -21 9 -26 54 -8 65
  -1 92 26 99 18 5 19 8 7 26 -7 12 -23 21 -35 21 -29 0 -29 4 3 46 25 33 26 37
  14 74 -7 22 -19 40 -27 40 -8 0 -11 5 -8 10 4 6 23 7 46 4 35 -6 39 -4 39 14
  0 12 -9 26 -20 32 -35 19 -22 29 33 25 28 -2 54 -4 56 -4 3 -1 -7 -13 -21 -28
  -14 -16 -33 -43 -42 -61 -16 -32 -16 -32 9 -42 36 -14 42 -13 55 8 9 14 10 13
  4 -8 -3 -14 -12 -39 -19 -56 -12 -29 -11 -34 13 -57 24 -25 25 -28 13 -68 -11
  -37 -11 -44 3 -55 9 -6 16 -18 16 -27 0 -9 25 -29 62 -47 49 -25 68 -30 99
  -25 52 9 64 25 49 70 -14 42 -22 43 -49 8 -30 -37 -37 -22 -14 29 28 60 53
  145 53 179 0 19 5 29 15 29 20 0 19 40 -2 55 -15 11 -15 14 5 40 l22 28 -25
  23 c-29 27 -31 41 -8 88 15 33 15 37 0 54 -9 10 -17 21 -17 25 0 7 29 11 83
  14 33 1 37 -2 37 -23z"
      />
      <path
        d="M10280 5110 c-17 -9 -41 -36 -55 -62 -24 -45 -25 -55 -25 -218 0
  -153 2 -175 21 -216 36 -76 58 -81 302 -83 231 -2 250 -6 285 -63 19 -32 20
  -52 20 -903 -1 -819 0 -872 17 -907 34 -69 141 -91 370 -79 106 6 151 13 185
  28 41 18 47 25 68 84 l23 64 -2 820 c-1 845 0 877 39 925 26 32 71 37 273 33
  177 -4 187 -3 223 18 63 37 73 70 80 274 6 177 6 181 -17 220 -13 22 -41 50
  -63 62 l-39 23 -837 -2 c-742 -2 -840 -4 -868 -18z m1487 -254 c37 -6 53 -13
  55 -24 7 -33 -39 -40 -243 -35 -159 3 -205 1 -254 -13 -33 -9 -69 -18 -80 -21
  -11 -2 -29 -19 -39 -36 -18 -31 -19 -71 -17 -907 0 -481 -2 -898 -5 -926 -5
  -43 -9 -51 -24 -47 -11 3 -20 17 -24 36 -3 18 -6 425 -8 906 l-2 874 -22 48
  c-17 36 -33 53 -65 70 -39 19 -53 21 -148 15 -131 -8 -103 -8 -256 2 -133 8
  -155 13 -155 36 0 23 98 28 364 22 138 -4 294 -3 346 1 116 10 506 9 577 -1z
  m-397 -800 c0 -2 -11 -28 -25 -58 -31 -67 -31 -78 0 -78 23 0 24 -2 15 -20
  -10 -19 -5 -23 26 -19 23 3 15 -73 -9 -97 -17 -18 -6 -71 23 -104 16 -19 29
  -45 30 -56 0 -18 -5 -20 -26 -16 -24 4 -25 3 -15 -16 14 -27 14 -129 0 -138
  -8 -4 -7 -9 2 -15 21 -13 -36 -22 -60 -9 -16 8 -21 20 -21 51 0 22 -7 56 -15
  76 -18 42 -17 44 12 37 14 -4 20 -11 16 -21 -4 -11 -2 -14 8 -11 21 8 12 51
  -16 71 -31 23 -33 62 -5 70 23 6 27 37 5 37 -22 0 -29 45 -15 96 11 38 11 47
  -3 62 -22 24 -2 112 33 141 23 19 40 26 40 17z m20 -712 c0 -14 -7 -27 -15
  -30 -20 -8 -19 -21 5 -46 21 -23 29 -108 10 -108 -5 0 -10 9 -10 19 0 27 -17
  35 -28 15 -13 -23 7 -70 35 -83 13 -6 23 -15 23 -20 0 -12 -35 -33 -42 -25 -3
  2 -11 -2 -17 -10 -19 -22 -4 -56 23 -56 37 0 51 -23 34 -60 -12 -29 -16 -31
  -57 -28 -54 3 -67 -18 -22 -35 17 -6 31 -15 31 -19 0 -4 7 -8 15 -8 20 0 39
  -70 31 -114 -5 -25 -14 -40 -29 -45 -12 -5 -31 -19 -42 -31 -18 -20 -26 -21
  -90 -15 -79 7 -105 13 -104 23 5 34 -16 44 -26 12 -12 -37 -42 -44 -108 -24
  l-59 18 5 38 c4 32 2 38 -14 38 -12 0 -19 7 -19 20 0 10 -5 30 -11 44 -16 34
  -3 75 26 86 24 9 24 10 8 30 -10 11 -25 20 -35 20 -24 0 -23 7 7 46 26 34 32
  59 15 69 -5 3 -10 15 -10 26 0 11 -7 19 -16 19 -8 0 -12 5 -9 10 4 6 23 7 46
  4 35 -6 39 -4 39 14 0 12 -9 26 -20 32 -33 18 -23 27 30 26 27 -1 50 -3 50 -4
  0 -2 -13 -22 -29 -45 -36 -51 -48 -87 -29 -87 7 0 23 -3 35 -6 18 -5 22 -1 26
  23 4 25 4 25 5 -7 1 -19 -5 -48 -13 -63 -16 -31 -8 -59 21 -75 14 -8 14 -14 3
  -52 -11 -38 -11 -44 4 -55 9 -7 17 -20 17 -29 0 -9 12 -22 28 -28 15 -7 40
  -21 55 -31 22 -15 38 -18 72 -13 58 9 70 25 54 71 -7 19 -16 35 -20 35 -11 0
  -39 -30 -39 -41 0 -5 -7 -9 -15 -9 -13 0 -12 7 5 41 30 58 62 168 56 191 -3
  14 1 22 15 25 25 7 24 31 -2 59 l-21 22 21 11 c27 15 27 42 1 59 -24 15 -25
  43 -4 88 15 32 15 36 0 53 -9 9 -16 20 -16 24 0 7 29 11 83 14 33 1 37 -2 37
  -23z"
      />
    </g>
  </svg>
);

export default IconLoader;
